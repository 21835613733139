<template>
    <div class="bg-f">
        <!--公共头部-->
        <Header></Header>
        <!-- 用户中心头部 -->
        <user-header />
        <!-- 内容 -->
        <div class="main-content">
            <div class="container">
                <div class="accout-content clearfix" style="display:flex">
                    <!-- 左边 -->
                    <user-left mark="exhibit"  :userId="userId"/>
                    <!-- 右边 -->
                    <a-card :bordered="false" class="card-right">
                        <div class="first">
                            <span><i style="color:red">*</i>{{ $t('user.product.name-zh') }}:</span>
                            <input type="text" v-model="productName">
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.product.name-en') }}:</span>
                            <input type="text" v-model="productNameEn">
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.product.category-one') }}:</span>
                            <a-select default-value="0" style="width: 310px" @change="handleChange">
                                <a-select-option value='0'>
                                    {{ $t('user.select-cate') }}
                                </a-select-option>
                                <a-select-option :value="item.categoryId" v-for="(item,index) in catelist1" :key="index">
                                    {{item.categoryName}}
                                </a-select-option>
                            </a-select>       
                        </div>
                        <div class="second" v-if="categoryId1!=0">
                            <span><i style="color:red">*</i>{{ $t('user.product.category-two') }}:</span>
                            <a-select default-value="" style="width: 310px" @change="handleChange2">
                               <a-select-option :value="item.categoryId" v-for="(item,index) in catelist2" :key="index">
                                    {{item.categoryName}}
                                </a-select-option>
                            </a-select>       
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.product.desc-zh') }}:</span>
                            <input type="text" v-model="productDesc">
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.product.desc-en') }}:</span>
                            <input type="text" v-model="productDescEn">
                        </div>
                        <div class="second">
                            <span>{{ $t('user.product.min-price1') }}:</span>
                            <input type="text" v-model="minPrice">
                        </div>
                        <div class="second">
                            <span>{{ $t('user.product.max-price1') }}:</span>
                            <input type="text" v-model="maxPrice">
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.product.min-order') }}:</span>
                            <input type="text" v-model="minOrder">
                        </div>
                        <div class="second">
                            <span>{{ $t('user.keywords-zh') }}:</span>
                            <input type="text" v-model="keywords">
                            &nbsp;{{ $t('user.keywords-more') }}
                        </div>
                        <div class="second">
                            <span>{{ $t('user.keywords-en') }}:</span>
                            <input type="text" v-model="keywordsEn">
                            &nbsp;{{ $t('user.keywords-more') }}
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.product.pay-method') }}:</span>
                            <input type="text" v-model="payMethod">
                        </div>
                        <div class="second">
                            <span style="float:left"><i style="color:red">*</i>{{ $t('user.product.img') }}:</span>
                            <a-upload
                                name='file'
                                list-type="picture-card"
                                :show-upload-list="false"
                                :customRequest="uploadImageFile"
                                :data="{ field: 'thumb' }"
                            >
                                <a-button :loading="thumbUploadStatus"> <a-icon type="upload" /> {{ $t('user.video.img-upload') }} </a-button>
                            </a-upload>
                            <img :src="thumb" alt="" v-if="thumb" width="100px" height="100px" style="margin-left:30px">
                        </div>
                        <div class="second">
                            <span style="float:left"><i style="color:red">*</i>{{ $t('user.product.imgs') }}:</span>
                            <a-upload
                                name='file'
                                list-type="picture-card"
                                :show-upload-list="false"
                                :customRequest="uploadImageFile"
                                :data="{ field: 'images' }"
                            >
                                <a-button :loading="imagesUploadStatus"> <a-icon type="upload" /> {{ $t('user.product.imgs-upload') }} </a-button>
                            </a-upload>
                            <div style="display:flex" v-if="images">
                                <div v-for="(item,index) in images" :key="index" style="display:inline-block;width:180px;height:180px;position:relative;overflow:hidden;margin-left:10px">
                                    <img :src="item" alt="" style="margin-left:10px">
                                    <a-icon type="close" style="position:absolute;top:0;right:0" @click="viedoRemove(index)"/>
                                </div>
                            </div>
                        </div>
                        <div class="second">
                          <span style="float:left">{{ $t('user.product.videos') }}:</span>
                           <a-upload
                                name='file'
                                list-type="picture-card"
                                :show-upload-list="false"
                                :customRequest="uploadVideoFile"
                                :data="{ field: 'videoUrls' }"
                            >
                                <a-button :loading="videoUrlsUploadStatus"> <a-icon type="upload" /> {{ $t('user.video-upload') }} </a-button>
                            </a-upload>
                            <div style="width:300px;height:180px;" v-show="videoUrls" v-for="(item,index) in videoUrls" :key="index">
                              <video :src="item.url" controls="controls" class="video"></video>
                            </div>
                        </div>
                        <button class="submit" @click="regsub()">{{ $t('user.submit') }}</button>
                    </a-card>
                </div>
            </div>
        </div>
        <!-- 公共尾部 -->
        <Footer></Footer>
    </div>
</template>

<style>
    .main-content .card-right{
        width: 100%;
    }
    .first,.second{
        text-align: left;
    }
    .second{
      display: flex;
    }
    .first>span,.second>span{
        font-size: 16px;
        width: 140px;
        display: inline-block;
    }
    input[type=text]{
      width: 300px;
      border: 1px solid #787878;
      margin-left: 10px;
    }
    .second{
        margin-top: 20px;
    }
    .submit{
        width: 200px;
        height: 40px;
        margin-top: 40px;
        border: 1px solid  #d9d9d9;
        line-height: 40px;
        background: #ff4d4f;
        color: #fff;
        font-size: 16px;
        border-radius: 4px;
    }
    .ant-select-selection--single{
        margin-left: 10px;
        border: 1px solid black;
    }
    .ant-upload.ant-upload-select-picture-card{
        margin-left: 10px;
    }
    .video{
      width: 100%;
      height: 100%;
    }
</style>

<script>
import UserHeader from './components/Header'
import UserLeft from './components/Left'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Editor from '@/components/Editor/TinyMceEditor'

export default {
  name: 'ExbitAdd',
  components: {
    UserHeader,
    Footer,
    UserLeft,
    Editor,
    Header
  },
  data () {
    return {
        userId: this.$route.params.userId,
        productName: '',
        productNameEn: '',
        categoryId1: 0,
        categoryId2: '',
        productDesc: '',
        productDescEn: '',
        minPrice: '',
        maxPrice: '',
        minOrder: '',
        keywords: '',
        keywordsEn: '',
        payMethod: '',
        thumb: '',
        images: [],
        catelist1: [],
        catelist2: [],
        videoUrls: [],
        thumbUploadStatus: false,
        imagesUploadStatus: false,
        videoUrlsUploadStatus: false
    }
  },
   mounted () {
    this.getList()
  },
   methods: {
    handleChange(value) {
      this.categoryId1 = value
       this.$common.fetchList('/exhibition/category/lists?parentId='+this.categoryId1, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.catelist2 = result
        }
      }).catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      })
      console.log(this.catelist2)
    },
    handleChange2(value) {
     
      this.categoryId2 = value
      console.log(this.categoryId2)
    },
    // 上传图片
    uploadImageFile (file) {
      console.log('file', file)
      const field = file.data.field
      this[field + 'UploadStatus'] = true // 按钮状态
      const formData = new FormData()
      formData.append('file', file.file)
      this.$common.uploadImageFile(formData).then(data => {
        console.log(data)
        if (data.code === 200) {
          const result = data.data
          if (field === 'images') {
            this.images.push(result.url)
          } else {
            this.thumb = result.url
          }
        } else {
          this.$message.error(data.msg)
        }
      }).catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      }).finally(() => {
        this[field + 'UploadStatus'] = false
      })
    },
     // 图片删除
    viedoRemove (index) {
      const len = this.images.length
      if (index <= len - 1) {
        this.images.splice(index, 1)
      }
    },
    // 获取行业列表
    getList () {
       this.$common.fetchList('/exhibition/category/lists?parentId='+this.categoryId1, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.catelist1 = result
        }
      }).catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      })
    },
    //上传视频
    uploadVideoFile (file) {
      console.log('file', file)
      const field = file.data.field
      this[field + 'UploadStatus'] = true // 按钮状态
      const formData = new FormData()
      formData.append('file', file.file)
      this.$common.uploadVideoFile(formData).then(data => {
        console.log(data)
        if (data.code === 200) {
          const result = data.data
          this.videoUrls.push({'url':result.url, 'thumb':result.thumb, 'duration': result.duration})
        } else {
          this.$message.error(data.msg)
        }
      }).catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      }).finally(() => {
        this[field + 'UploadStatus'] = false
      })
    },
    // 产品添加
    regsub() {
        // if (this.productName == '' || this.productNameEn == '' || this.categoryId1 == '' || this.categoryId2 == '' || this.productDesc == '' 
        //     || this.productDescEn == '' || this.minOrder == '' || this.payMethod == '' || this.thumb == '' || this.images == '') {
        //     this.$layer.alert("请正确填写信息");
        //     return 
        // }
        if (this.productName == '') {
          this.$layer.alert("中文产品名称不能为空");
          return 
        } else if (this.productNameEn == '') {
          this.$layer.alert("英文产品名称不能为空");
          return 
        } else if (this.categoryId1 == '') {
          this.$layer.alert("一级分类不能为空");
          return 
        } else if (this.categoryId2 == '') {
          this.$layer.alert("二级分类不能为空");
          return 
        } else if (this.productDesc == '') {
          this.$layer.alert("中文产品描述不能为空");
          return 
        } else if (this.productDescEn == '') {
          this.$layer.alert("英文产品描述不能为空");
          return 
        } else if (this.minOrder == '') {
          this.$layer.alert("最小订单数不能为空");
          return 
        } else if (this.payMethod == '') {
          this.$layer.alert("支付方式不能为空");
          return 
        } else if (this.thumb == '') {
          this.$layer.alert("产品封面图不能为空");
          return 
        } else if (this.images == '') {
          this.$layer.alert("产品图片集不能为空");
          return 
        }
        const params = {
            productName: this.productName,
            productNameEn: this.productNameEn,
            categoryId1: this.categoryId1,
            categoryId2: this.categoryId2,
            productDesc: this.productDesc,
            productDescEn: this.productDescEn,
            minPrice: this.minPrice,
            maxPrice: this.maxPrice,
            minOrder: this.minOrder,
            keywords: this.keywords,
            keywordsEn: this.keywordsEn,
            payMethod: this.payMethod,
            thumb: this.thumb,
            images: this.images,
            medias: this.videoUrls,
            threeds: this.threeds
        }
        this.$common.handlePost('/user/product/add', params).then(data => {
        if (data.code === 200) {
            this.$layer.alert(data.msg);
            this.$router.push({name: 'UsersExhibit', params: {userId: this.userId}})
        } else {
            this.$layer.alert(data.msg);
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    }
   }
}
</script>